import React, { CSSProperties } from 'react'

import { UtilColor } from 'Util'
import { ComponentEuiFlexGroup, ComponentEuiFlexItem } from 'Component'
import { FORMAT_NODE_INTERFACE } from 'Format'
import {
    TypeEntityCellType,
    TypeNav,
    TypeNavHttpControlAction,
    TypeNavHttpControlActionAuto
} from 'Type'
import { ModuleNodeFieldName, ModuleNodeFieldRequired, ModuleNodeFieldType } from 'Module'
import {HookNavAction, HookNavActionAuto} from 'Hook'

import './ModuleNodeField.css'

const style :CSSProperties = {
    padding: '8px',
    height: FORMAT_NODE_INTERFACE.height,
    width: FORMAT_NODE_INTERFACE.width,
    backgroundColor: 'white',
    lineHeight: 1,
    visibility: 'visible'
}

type Props = {
    name :string
    type :TypeEntityCellType
    isRequired :boolean
    color :string
    nav? :TypeNav<any>
    refreshAll :() => void
}

const buildStyle = (color :string) :CSSProperties => {
    return {
        ...style,
        border: `1px solid ${UtilColor.shade(color, 0)}`,
        color: UtilColor.shade(color, -30),
    }
}

const ModuleNodeField = ({ name, type, isRequired, color, nav, refreshAll }: Props) => {

    const deleteAction :TypeNavHttpControlAction<any> = HookNavAction<any>({actionType: 'delete', nav})

    const actionControl :TypeNavHttpControlActionAuto<any> = HookNavActionAuto<any>('patch', nav)

    const updated :any | undefined = actionControl.res?.data?.data.entity

    return <div style={buildStyle(color)}>
        <ModuleNodeFieldName name={updated?.name ?? name} deleteAction={deleteAction} refreshAll={refreshAll}/>
        <ComponentEuiFlexGroup>
            <ComponentEuiFlexItem grow={1}>
                <ModuleNodeFieldType type={updated?.type ?? type} actionControl={actionControl} color={color}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                <ModuleNodeFieldRequired isRequired={updated?.isRequired ?? isRequired} color={color} actionControl={actionControl}/>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup>
    </div>
}

export default ModuleNodeField