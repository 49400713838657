import {TypeEntityTransformHeader, TypeEntityTransformHeaderError } from 'Type'

import './ModuleTransformOutputTableCell.css'

type Props = {
    value :string
    header :TypeEntityTransformHeader
    error? :TypeEntityTransformHeaderError
}

type PropsCellDiv = {
    className :string
    value :any
}

const CellDiv = ({ className, value } :PropsCellDiv) => {
    return <div className={className}>
        <div>{value}</div>
    </div>
}

const ModuleTransformOutputTableCell = ({value, header, error}: Props) => {
    if (!error) {
        console.log('no error')
        return <CellDiv className={'transformation-error-cell-inner'} value={value}/>
    }
    if (error.fixValue) {
        console.log('fixed error')
        return <CellDiv className={'transformation-error-cell-inner fixed'} value={error.fixValue}/>
    }
    if (error.cellValue === '' && header.defaultValue) {
        console.log('default value', error, header)
        return <CellDiv className={'transformation-error-cell-inner fixed'} value={header.defaultValue}/>
    }
    return <CellDiv className={'transformation-error-cell-inner error'} value={error.cellValue}/>
}

export default ModuleTransformOutputTableCell