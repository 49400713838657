import {CSSProperties, PropsWithChildren} from 'react'


type Props = {
    padding :number
    style? :CSSProperties
}

const ComponentDivPadding = ({ padding, style, children } :PropsWithChildren<Props>) => {

    const styleInner :CSSProperties = style ?? {}
    styleInner.padding = `${padding}px`

    return <div style={styleInner}>{children}</div>
}

export default ComponentDivPadding