import { ModuleTableDataTable } from 'Module'
import { TypeModuleTableDataProps } from 'Type'
import { ComponentDivMargin } from 'Component'


const PageTableBody = (tableProps :TypeModuleTableDataProps) => {
    return <ComponentDivMargin margin={-1}>
        <ModuleTableDataTable {...tableProps}/>
    </ComponentDivMargin>
}

export default PageTableBody