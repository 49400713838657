import {CSSProperties, useContext} from 'react'
import {UtilColor, UtilNode} from 'Util'
import { TypeContextInterfaceNodes, TypeEntityInterface, TypeReactFlowNodeProps} from 'Type'
import { FORMAT_NODE_INTERFACE } from 'Format'
import { ContextInterfaceNodes } from 'Context'


const basicStyle :CSSProperties = {
    padding: FORMAT_NODE_INTERFACE.padding,
    width: (FORMAT_NODE_INTERFACE.padding * 2) + FORMAT_NODE_INTERFACE.width,
    border: `1px solid ${UtilColor.shade(FORMAT_NODE_INTERFACE.color.iface, 0)}`,
    color: UtilColor.shade(FORMAT_NODE_INTERFACE.color.iface, -30),
    backgroundColor: UtilColor.shade(FORMAT_NODE_INTERFACE.color.iface, 130),
    visibility: 'visible'
}

const titleStyle :CSSProperties = {
    fontWeight: 600,
}

const ModuleInterfaceNode = ({ data } :TypeReactFlowNodeProps<TypeEntityInterface>) => {

    const context :TypeContextInterfaceNodes = useContext(ContextInterfaceNodes)

    const height :number = (context.fields.length) ? UtilNode.calcWrapperHeight(context.fields.length, !data.isComplete) : UtilNode.calcWrapperHeightCollapsed(data.isComplete)

    const style :CSSProperties = {
        ...basicStyle,
        height: `${height}px`
    }

    return <div style={style}>
        <div className={'eui-textTruncate'} style={titleStyle}>{data.name}</div>
    </div>
}

export default ModuleInterfaceNode