import {FC, useContext } from 'react'
import { ContextTableData } from 'Context'
import { TypeContextTableData, TypeEntityRow, TypeEntityTableVersionColumn, TypeModuleTableDataCellProps,
    TypeModuleTableDataCellValue, TypeNavSocketPayload } from 'Type'
import { FORMAT_TABLE_DATA_SYSTEM_COLUMNS } from 'Format'
import { RecordTableDataCellDisplay } from 'Record'
import {FORMAT_TABLE_COLOR_FLASH_MS} from 'Format'
import { HookModuleTableDataContextRow } from 'Hook'

const updatedBy = FORMAT_TABLE_DATA_SYSTEM_COLUMNS.updatedBy

const BLANK :TypeModuleTableDataCellValue = { displayCellValue: undefined, color: undefined }

const HookModuleTableDataCellValue = (rowIndex :number, columnNameSlug :string) :TypeModuleTableDataCellValue => {

    const context :TypeContextTableData = useContext(ContextTableData)

    const row :TypeEntityRow | undefined = HookModuleTableDataContextRow(rowIndex)
    if (!row) return BLANK
    const rowData :any = row

    const column :TypeEntityTableVersionColumn | undefined = context.columns[columnNameSlug]
    if (!column) return BLANK

    const filterSocketPayloadHasColumn = (socketPayload :TypeNavSocketPayload<any>) :boolean => socketPayload.body.hasOwnProperty(columnNameSlug)

    const getLastUpdate = () :TypeNavSocketPayload<any> | undefined => {
        const rowUpdates :TypeNavSocketPayload<number>[] | undefined = context.updates[row.rowId]
        if (!rowUpdates) return undefined
        return rowUpdates.filter(filterSocketPayloadHasColumn).at(-1);
    }

    const lastUpdate :TypeNavSocketPayload<any> | undefined = getLastUpdate()
    const lastUpdatedBy :string | undefined = (lastUpdate?.body.hasOwnProperty(updatedBy)) ? lastUpdate.body[updatedBy] : undefined
    const lastUpdateTimestampMs :number | undefined = lastUpdate?.clientTimestampMs

    const value :any | undefined = lastUpdate?.body[columnNameSlug] ?? rowData[columnNameSlug]
    const color :string | undefined = (lastUpdateTimestampMs && (lastUpdateTimestampMs + FORMAT_TABLE_COLOR_FLASH_MS) > Date.now()) ? lastUpdatedBy && context.colors[lastUpdatedBy] : undefined

    const DisplayCellValue :FC<TypeModuleTableDataCellProps> = RecordTableDataCellDisplay[column.type]

    return { displayCellValue: <DisplayCellValue column={column} row={row} value={value}/>, color }
}

export default HookModuleTableDataCellValue