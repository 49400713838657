import {
    ComponentDivMarginHorizontal,
    ComponentDivPadding,
    ComponentEuiBeacon,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
    ComponentEuiTablePagination,
    ComponentNavActionDownloadButton
} from 'Component'
import { HookNavPaginatePage } from 'Hook'
import { TypeNav, TypeWebSocketControl } from 'Type'

type Props = {
    nav? :TypeNav<any>
    itemsPerPageOptions? :number[]
    socketControl? :TypeWebSocketControl<any>
    prependPath? :string
}

const ComponentNavDataPagePagination = ({ nav, itemsPerPageOptions = [5, 10, 25, 50, 100], socketControl, prependPath } :Props) => {

    const { size, page, pageCount, setPage, setSize } = HookNavPaginatePage({ nav, prependPath})

    return <ComponentDivPadding padding={4} style={{borderTop: '1px solid rgb(205, 211, 223)'}}>
        <ComponentEuiFlexGroup alignItems={'center'} gutterSize={'s'}>
            { nav && <ComponentNavActionDownloadButton nav={nav}/> }
            <ComponentEuiFlexItem grow>
                <ComponentEuiTablePagination showPerPageOptions={true} itemsPerPage={size} activePage={page} pageCount={pageCount}
                     itemsPerPageOptions={itemsPerPageOptions} onChangeItemsPerPage={setSize} onChangePage={setPage}/>
            </ComponentEuiFlexItem>
            {socketControl && <ComponentEuiFlexItem>
              <ComponentDivMarginHorizontal margin={10}>
                <ComponentEuiBeacon color={(socketControl.isConnected) ? 'success' : 'subdued'}/>
              </ComponentDivMarginHorizontal>
            </ComponentEuiFlexItem>}
        </ComponentEuiFlexGroup>
    </ComponentDivPadding>
}

export default ComponentNavDataPagePagination